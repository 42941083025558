import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Modal, Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import ColumnParams from '../plannings/drawer/ColumnParams';
import { notificationSuccess } from '../../helpers/notification';
import GanttPreferencesGlobal from '../plannings/setting/GanttPreferences';

const { TabPane } = Tabs;

const PreferencesMenu = () => {
    const {t} = useTranslation('translation', {keyPrefix: 'user_preference'})
    const generalTranslation = useTranslation('translation', {keyPrefix: 'general'}).t
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Menu.Item key="userpreference_menu" onClick={showModal}>
                <FontAwesomeIcon icon={faWrench} fixedWidth />
                {t('my_preferences')}
            </Menu.Item>
            <Modal
                title={t('default_preference')}
                centered
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                width={926}
            >
                <Tabs defaultActiveKey="column_pref">
                    <TabPane tab={t('column_tab')} key="column_pref">
                        <ColumnParams isPlanning={false} onCancel={() => setIsModalVisible(false)} onOk={() => notificationSuccess(t('default_columns'), generalTranslation('saved_sucess'))}/>
                    </TabPane>
                    <TabPane tab={t('gantt_tab')} key="gantt_pref">
                        <GanttPreferencesGlobal onCancel={() => setIsModalVisible(false)} onOk={() => notificationSuccess(t('gantt_preference'), generalTranslation('saved_success'))}/>
                    </TabPane>
                </Tabs>
            </Modal>
        </>
    );
};

export default PreferencesMenu;
