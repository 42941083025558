import React, { useContext, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Col, Form, Modal, Select, Tooltip, Row, Button, Empty, Typography, Progress } from 'antd';
import styled from 'styled-components';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { PrintTemplatesMenuContext } from '../../contexts/app/PrintTemplatesMenuContext';
import { PrintMenuContext } from '../../contexts/app/PrintMenuContext';
import { NeedsContext } from '../../contexts/needs/NeedsContext';
import { PlanningNeedsContext } from '../../contexts/needs/PlanningNeedsContext';
import PrintTemplateService from '../../services/print-template.service';
import { ReactComponent as PrintSvg } from '../../assets/icons/print.svg';
import { initPrint } from '../print/export-dhtmlx';
import TaskStripes from '../../helpers/task-stripes';
import { downloadPDF, mergePDFBuffers } from '../../helpers/pdf';
import { requestError } from '../../helpers/notification';
import linkStyle from '../../helpers/link-style';
import { getArrayInListByCondition } from '../../helpers/tree-helper';
import { RESOURCE_VIEW_MODE } from '../../constants/Generic';
import { NEED_TYPE } from '../../constants/Needs';
import { selectPlanningSelected, selectTasksStripesStyles } from '../../redux/slices/app/planning.slice';
import customGanttCss from '../print/custom-gantt-css';
import { refreshPlanningSelected } from '../../redux/thunks/planning-slice.thunk';
import { selectUserPreferences } from '../../redux/slices/app/user.slice';
import { selectTabPreferences } from '../../redux/slices/app/tab.slice';
import { selectNeedList } from '../../redux/slices/chart/need-chart.slice';

const { Text } = Typography;

const apiUrl = window._env_.REACT_APP_PRINT_URL.replace('/gantt', '');
// import { zoomGanttToFit } from '../../helpers/zoom';
/* eslint-disable camelcase */
const QuickButton = styled.button`
    width: 32px;
    height: 32px;
`;



const PrintMenu = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'modal_print' });
    const generalTranslation = useTranslation('translation',  { keyPrefix: 'general' }).t;
    const needsTranslation = useTranslation('translation',  { keyPrefix: 'needs' }).t;
    const { printTemplates } = useContext(PrintTemplatesMenuContext);
    const { onModalClose, isModalVisible, setIsModalVisible } = useContext(PrintMenuContext);
    const ganttHistogramChartNeedList = useSelector(selectNeedList); // TODO REDUX check it
    const planningSelected = useSelector(selectPlanningSelected);
    const tasksStripesStyles = useSelector(selectTasksStripesStyles);
    const userPreferences = useSelector(selectUserPreferences);
    const tabPreferences = useSelector(selectTabPreferences);
    const globalNeedList = useContext(NeedsContext).NeedList;
    const planningNeedList = useContext(PlanningNeedsContext).NeedList;
    const [form] = Form.useForm();
    const [printLoading, setPrintLoading] = useState(false);
    const [progressLabel, setProgressLabel] = useState(false);
    const [progressCount, setProgressCount] = useState(0);

    const HEADER_FOOTER_HEIGHT = 100;
    const HEADER_FOOTER_PADDING = 10;

    const showModal = () => {
        // refreshPrintTemplate();
        // disable scollstate saving
        window.saveScrollState = false;
        window.ganttInstance.scrollTo(0, null);
        setIsModalVisible(true);
    };

    const getPositionClasses = (printTemplatePlacement) => {
        const classes = [];

        if (printTemplatePlacement) {
            switch (printTemplatePlacement.alignment) {
                case 'CENTER':
                    classes.push('justify-content: center;');
                    break;
                case 'RIGHT':
                    classes.push('justify-content: flex-end;');
                    break;
                default:
                    classes.push('justify-content: flex-start;');
                    break;
            }

            switch (printTemplatePlacement.position) {
                case 'BOTTOM':
                    classes.push('align-items: flex-end;');
                    break;
                case 'CENTER':
                    classes.push('align-items: center;');
                    break;
                default:
                    classes.push('align-items: flex-start;');
                    break;
            }
        }

        return classes.join(' ');
    };

    const valueToHtml = ({ value, type, alignment }) => {
        const today = moment().format(window.dateStringFormat);
        switch (type) {
            case 'IMAGE':
                return `<div style="height: 50%; width: 50%; border: none; background-position: ${alignment.toLowerCase()}; background-size: auto 100%; background-repeat: no-repeat; background-image: url(${value})" ></div>`;
            case 'DATE':
                return `<p style="margin: 0;">${today}</p>`;
            case 'PAGE':
                return `<p style="margin: 0;">{{pageInfo}}</p>`;
            case 'PROJECT_NAME':
                return `<p style="margin: 0;">${planningSelected.name}</p>`;
            default:
                return `<p style="margin: 0;">${value}</p>`;
        }
    };

    const paperSizes = ['A3', 'A4', 'A5', 'Letter', 'Legal', 'Tabloid'];
    // const paperSizes = ['A0', 'A1', 'A2','A3', 'A4', 'A5', 'Letter', 'Ledger', 'Arch_C', 'Arch_D', 'Arch_E'];
    // const taskCountBySize = {
    //     A3: 46,
    //     A4: 29,
    //     A5: 19,
    // };
    const paperHeight = {
        A0: 840,
        A1: 594,
        A2: 420,
        A3: 297,
        A4: 210,
        A5: 148,
        Letter: 215,
        Ledger: 279,
        Arch_C: 457,
        Arch_D: 609,
        Arch_E: 914,
        Legal: 216,
        Tabloid: 279,
    };
    const paperWidth = {
        A0: 1188,
        A1: 840,
        A2: 594,
        A3: 420,
        A4: 297,
        A5: 210,
        Letter: 279,
        Ledger: 431,
        Arch_C: 609,
        Arch_D: 914,
        Arch_E: 1219,
        Legal: 356,
        Tabloid: 432,
    };

    // calculate the number of tasks can be printed in one page (according to paper height)
    const getTaskCountToPrint = (pageHeight) => {
        // => widthPx = widthMM / (25.4 [inch in MM] / 144 [PDF PPI]);
        const converter = 25.4 / 144;
        const totalPageHeightPx = pageHeight / converter;
        const { scale_height, row_height } = window.ganttInstance.config;
        const taskCount = (totalPageHeightPx - HEADER_FOOTER_HEIGHT - HEADER_FOOTER_HEIGHT - scale_height) / row_height;
        return Math.trunc(taskCount - 1);
    };

    const buildHeaderFooterHTML = (printTemplate) => {
        let customStyles = '';
        TaskStripes.buildStyles(tasksStripesStyles).forEach((item) => {
            customStyles = `${customStyles} ${item}`;
        });
        // dashed links
        const linksData = window.ganttInstance
            .getLinks()
            .filter((link) => linkStyle.isDotted(link.dataAPI))
            .map((link) => linkStyle.formatForState(link.dataAPI));
        linkStyle.buildStyles(linksData).forEach((item) => {
            customStyles = `${customStyles} ${item}`;
        });
        // critical task
        if (tabPreferences?.gantt_parameters?.showCriticalPath) {
            customStyles = ` ${customStyles}
.gantt_task_line.critical-task {
    box-shadow: 0 0 0 3px ${tabPreferences?.gantt_parameters?.criticalPathColor ?? 'red'} !important;
}`;
        }
        // grid header
        if (userPreferences?.gantt_style?.gridHeaderColors) {
            customStyles = ` ${customStyles} 
    .gantt_grid_scale, .gantt_grid_head_cell {
        color: ${userPreferences?.gantt_style?.gridHeaderColors.gridTextColor} !important;
        background-color: ${userPreferences?.gantt_style?.gridHeaderColors.gridBackgroundColor} !important ;
    }`;
        }
        const simpleActivityPolice = tabPreferences?.gantt_simple_activity_parameters;
        if (simpleActivityPolice) {
            customStyles = `${customStyles} 
.simple-activity {
    ${simpleActivityPolice.show ? `background: ${simpleActivityPolice.color} !important;` : ''} 
    ${simpleActivityPolice.show ? `color: ${simpleActivityPolice.fontColor} !important;` : ''} 
}
.simple-task.gantt-resource-task .gantt_tree_indent {
    ${simpleActivityPolice.show ? `background: transparent !important; color: black;` : ''} 
}
.simple-task.gantt-resource-task {
    ${
        simpleActivityPolice.show
            ? `background: ${simpleActivityPolice.color.concat(40)} !important; color: black;`
            : ''
    } 
}

.simple-activity .gantt_tree_content {
    ${
        simpleActivityPolice.fontSize && simpleActivityPolice.show
            ? `font-size: ${simpleActivityPolice.fontSize} !important;`
            : ''
    } 
    ${simpleActivityPolice.fontStyle === 'bold' && simpleActivityPolice.show ? `font-weight: 700 !important;` : ''} 
    ${simpleActivityPolice.fontStyle === 'italic' && simpleActivityPolice.show ? `font-style: italic !important;` : ''} 
    ${
        simpleActivityPolice.fontStyle === 'bold-italic' && simpleActivityPolice.show
            ? `font-weight: 700 !important; font-style: italic !important;`
            : ''
    } 
}`;
        }

        // level colors
        (tabPreferences?.gantt_level_parameters ?? []).forEach((p, index) => {
            customStyles = `${customStyles} 
                .simple-activity .gantt_cell_tree .gantt_tree_indent:nth-of-type(${
                    index + 1
                }), .gantt_row_project .gantt_cell_tree .gantt_tree_indent:nth-of-type(${index + 1}),.gantt-resource-task .gantt_cell_tree .gantt_tree_indent:nth-of-type(${
                    index + 1
                }), .level-${index} {
    ${p.show ? `background: ${p.color} !important;` : ''} 
    ${p.show ? `color: ${p.fontColor} !important;` : ''} 
}
.level-${index} .gantt_tree_content {
    ${p.fontSize && p.show ? `font-size: ${p.fontSize} !important;` : ''} 
    ${p.fontStyle === 'bold' && p.show ? `font-weight: 700 !important;` : ''} 
    ${p.fontStyle === 'italic' && p.show ? `font-style: italic !important;` : ''} 
    ${p.fontStyle === 'bold-italic' && p.show ? `font-weight: 700 !important; font-style: italic !important;` : ''} 
}
.level-${index}.gantt-resource-task  {
    ${p.show ? `background: ${p.color.concat(40)} !important;color: black;` : ''} 

}

`;
        });
        // if (values.printColors) {
        (getArrayInListByCondition(globalNeedList, 'isLevel', false) ?? []).forEach((p) => {
            customStyles = `${customStyles} 
        .resource-task-${p.id} {
            background: ${p.color ?? '#000000'} !important;
        }
        .resource-subtask-${p.id} {
            background: ${(p.color ?? '#000000').concat('90')} !important;
        }
        `;
        });
        (getArrayInListByCondition(planningNeedList, 'isLevel', false) ?? []).forEach((p) => {
            customStyles = `${customStyles} 
        .resource-task-${p.id} {
            background: ${p.color ?? '#000000'} !important;
        }
        .resource-subtask-${p.id} {
            background: ${(p.color ?? '#000000').concat('90')} !important;
        }
        `;
        });
        // }
        // marker style
        (tabPreferences?.gantt_markers ?? [])
            .filter((i) => i.show)
            .forEach((p) => {
                customStyles = `${customStyles} 
.${p.markerClass}, .${p.markerClass}.gantt_marker_content {
    background: ${p.lineColor ?? 'red'} !important;
    color: ${p.fontColor ?? 'black'} !important;
}
`;
            });

        if (tabPreferences?.gantt_baseline?.showBaseLine) {
            const marginTop = ((tabPreferences.gantt_parameters?.rowHeight || 30) * 0.7) / 4;
            customStyles = `${customStyles} 
    .gantt_task_line, .gantt_line_wrapper, .freeSlack, .totalSlack {
        margin-top: -${marginTop}px !important;
    }
    .gantt_task_link .gantt_link_arrow { 
        margin-top: -${marginTop + 3}px !important;
    }
    `;
        }
        const result = {
            header: `
            <style>
            
                .gantt_container {
                    font-family: ${tabPreferences?.gantt_parameters?.fontFamily ?? 'Arial'};
                }
                    ${customGanttCss}
                    ${customStyles}
                    @page {
                        margin: 0;
                      }
                      .footer-container {
                        position: fixed;
                        bottom: 0;
                      }	
            @media print { 
                    .footer-container {
                        position: fixed;
                        bottom: 0;
                      }
            }
            </style>
                <div style="display: flex; height: ${HEADER_FOOTER_HEIGHT}px; background: ${
                printTemplate.color ? printTemplate.color.header : 'white'
            };">
                    <div style="flex: 1; padding: ${HEADER_FOOTER_PADDING}px; display: flex; ${getPositionClasses(
                printTemplate.headerLeft
            )}">
                        ${printTemplate.headerLeft ? valueToHtml(printTemplate.headerLeft) : ``}
                    </div>
                    <div style="flex: 1; padding: ${HEADER_FOOTER_PADDING}px; display: flex; ${getPositionClasses(
                printTemplate.headerCenter
            )}">
                        ${printTemplate.headerCenter ? valueToHtml(printTemplate.headerCenter) : ``}
                    </div>
                    <div style="flex: 1; padding: ${HEADER_FOOTER_PADDING}px; display: flex; ${getPositionClasses(
                printTemplate.headerRight
            )}">
                        ${printTemplate.headerRight ? valueToHtml(printTemplate.headerRight) : ``}
                    </div>
                </div>`,
            footer: `
                <div class="footer-container" style="display: flex; height: ${HEADER_FOOTER_HEIGHT}px; width: 100%; background: ${
                printTemplate.color ? printTemplate.color.footer : 'white'
            };">
                    <div style="flex: 1; padding: ${HEADER_FOOTER_PADDING}px; display: flex; ${getPositionClasses(
                printTemplate.footerLeft
            )}">
                        ${printTemplate.footerLeft ? valueToHtml(printTemplate.footerLeft) : ``}
                    </div>
                    <div style="flex: 1; padding: ${HEADER_FOOTER_PADDING}px; display: flex; ${getPositionClasses(
                printTemplate.footerCenter
            )}">
                        ${printTemplate.footerCenter ? valueToHtml(printTemplate.footerCenter) : ``}
                    </div>
                    <div style="flex: 1; padding: ${HEADER_FOOTER_PADDING}px; display: flex; ${getPositionClasses(
                printTemplate.footerRight
            )}">
                        ${printTemplate.footerRight ? valueToHtml(printTemplate.footerRight) : ``}
                    </div>
                </div>`,
        };
        return result;
    };
    /* eslint-disable-next-line */
    const getFileFromServer = async (data) => {
        const docUrl = data.url.replace('http://localhost:3200', apiUrl);
        return axios
            .request({
                responseType: 'arraybuffer',
                url: docUrl,
                method: 'get',
                headers: {
                    'Content-Type': 'application/pdf',
                },
            })
            .catch((e) => {
                requestError(e, t('print_planning'));
            });
    };
    /**
     * Send request for printing gantt or  gantt + histogram in same page
     * @param {*} values printing parameters
     * @param {*} printTemplate informations of template
     * @param {*} pageInfo page number
     * @param {*} type pdf of pdf-histogram
     * @param {*} extraData other data to send
     * @returns PDF buffer
     */
    const generatePDF = async (values, pageInfo, header, footer, type, extraData) =>
        new Promise((resolve) => {
            const initialColumns = window.ganttInstance.config.columns;

            const taskQuickInfo = window.ganttInstance.ext.quickInfo;
            taskQuickInfo.hide();
            window.ganttInstance.config.columns = initialColumns.filter(
                (column) => ['add', 'action_buttons', 'split_task'].indexOf(column.id) < 0
            );

            window.ganttInstance.exportToType(
                {
                    name: `${planningSelected.name}.pdf`,
                    header: header.replace('{{pageInfo}}', pageInfo),
                    footer: footer.replace('{{pageInfo}}', pageInfo),
                    columns_to_keep: initialColumns.filter(
                        (column) => ['add', 'action_buttons'].indexOf(column.id) < 0
                    ),
                    initial_columns: initialColumns,
                    additional_settings: {
                        format: values.format,
                        landscape: true,
                    },
                    raw: true,
                    type,
                    ...extraData,
                    config: {},
                    // get the file generated
                    callback: (data) => {
                        if (data) {
                            resolve(data)
                        } else {
                            requestError('', t('print_planning'));
                            resolve(null);
                        }
                    },
                },
                type
            );
            window.ganttInstance.config.columns = initialColumns;
            window.ganttInstance.config.autosize = false;
            window.ganttInstance.render();
        });
    /**
     * Send request for printing histogram
     * @param {*} values printing parameters
     * @param {*} printTemplate informations of template
     * @param {*} pageInfo page number
     * @param {*} type usually pdf
     * @param {*} extraData other data to send
     * @returns PDF buffer
     */
    const generateHistogramPDF = async (values, pageInfo, header, footer, type, extraData) =>
        new Promise((resolve) => {
            window.ganttInstance.exportHistogram(
                {
                    name: `histogram.pdf`,
                    header: header.replace('{{pageInfo}}', pageInfo),
                    footer: footer.replace('{{pageInfo}}', pageInfo),
                    additional_settings: {
                        format: values.format,
                        landscape: true,
                    },
                    type,
                    ...extraData,
                    config: {},
                    // get the file generated
                    callback: async (data) => {
                        if (data) {
                            resolve(data);
                        } else {
                            requestError('', t('print_planning'));
                            resolve(null);
                        }
                    },
                },
                type
            );
        });

    /**
     * Function for calculating histogram dimensions in the paper
     * @returns Height an Width in paper
     */
    const computeHistogramPDFDimension = () => {
        const histogramArea = document.getElementById('gantt-histogram-area');
        const histogramDimensions = {
            height: histogramArea.offsetHeight,
            width: histogramArea.offsetWidth,
        };
        return histogramDimensions;
    };

    /**
     *
     * @param {*} legends : Array of legends to display
     * @param {*} width : Width of the container
     * @param {*} height : Height of the container
     * @returns
     */
    const generateLegendsHTML = (legendsArray, width, height) => {
        let html = '';
        const legendsTemp = legendsArray.slice(0);
        const legends = [...legendsTemp];
        let legendType = '';
        if (legendsArray.length > 0) {
            const {needType} = legendsArray[0];
            switch (needType) {
                case NEED_TYPE.CONSUMMABLE:
                    legendType = needsTranslation('consummable')
                    break;
                case NEED_TYPE.NON_CONSUMMABLE:
                    legendType = needsTranslation('non_consummable')
                    break;
            
                default:
                    break;
            }
        }
        // let currentHeight = 0;
        const LEGEND_HEIGHT = 25;
        const LEGEND_WIDTH = 210;
        const numberOfColumns = Math.trunc(width / LEGEND_WIDTH);
        const numberOfLines = Math.trunc((height - LEGEND_HEIGHT) / LEGEND_HEIGHT);
        let shouldBreak = false;
        let isLegendFit = true;
        /* eslint-disable-next-line */
        for (let i = 0; i < numberOfColumns; i++) {
            if (shouldBreak) {
                break;
            }
            let columnHTML = `<div style="display: flex; flex-direction: column;
            width: ${LEGEND_WIDTH}px; height: ${height-LEGEND_HEIGHT}px; padding-right: 20px; margin-right: 10px;">`;
        /* eslint-disable-next-line */
            for (let j = 0; j < numberOfLines; j++) {
                if (legends.length === 0) {
                    shouldBreak = true;
                    break;
                }
                // check if last legend
                if (i === numberOfColumns - 1 && j === numberOfLines - 1 && legends.length > 0) {
                    isLegendFit = false;
                    columnHTML += `<span>${t('more_legend')}</span>`;
                } else {
                    const legend = legends.pop();
                    const legendElementHTML = `
                <div style="display: flex; align-items: center; justify-content: space-between; width: ${LEGEND_WIDTH}px; margin-bottom: 5px;">
                <span style="overflow: hidden; margin-right: 10px; text-overflow: ellipsis; white-space: nowrap; width: ${LEGEND_WIDTH - 30}px;">${legend.name}</span>
                <span style="display: inline-block; width: 20px; height: 20px; background-color: ${legend.color};"></span>
                </div>
              `;
                    columnHTML += legendElementHTML;
                }
            }
            columnHTML += '</div>';
            html += columnHTML;
        }

        const result = `<div style="display: flex; flex-direction: column; width: ${width}px; height: ${height}px; margin-left: 5px;"><p style="font-weight: bold; width: ${width}px; height: ${LEGEND_HEIGHT}px; margin: 0px; margin-bottom: 10px;">${t('legends')} :${legendType}</p><div style="display: flex; width: ${width}px; height: ${height-LEGEND_HEIGHT}px;   padding: 10px;">${html}</div></div>`;

        return { result, isLegendFit };
    };

    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    const submitForm = async (values) => {
        const startProcess = Date.now();
        setPrintLoading(true);
        // hide original histogram lines
        const lines = document.querySelector('.recharts-line-curve');
        if (lines) {
            lines.style.display = 'none';
        }
        setProgressCount(0);
        setProgressLabel('');
        const printTemplate = await PrintTemplateService.showTemplate(values.printTemplate);
        // generating header and footer
        const headerAndFooter = buildHeaderFooterHTML(printTemplate);
        // TODO desactiver pour la version @BETA
        const isShowingNeeds = tabPreferences?.gantt_parameters?.showResources === RESOURCE_VIEW_MODE(i18n).NEEDS.value;
        // isShowingNeeds = false;
        window.ganttInstance.unselectTask();
        const nbTaskToPrint = getTaskCountToPrint(paperHeight[values.format]);
        let minIndex = 0;
        let maxIndex = nbTaskToPrint;
        let currentPage = 1;
        // const pdfBuffers = [];
        initPrint();
        // get table column width for gantt
        const totalColumnWidth = window.ganttInstance.config.columns.reduce((acc, c) => acc + c.width, 0);
        const ganttData = window.ganttInstance.serialize().data;
        let totalPages = Math.ceil(ganttData.length / (nbTaskToPrint + 1));
        let canFit = false;
        if (isShowingNeeds) {
            // if modulo === 0, page is full of tasks
            const tasksInLastPage = ganttData.length % (nbTaskToPrint + 1) || nbTaskToPrint + 1;
            // => widthPx = widthMM / (25.4 [inch in MM] / 144 [PDF PPI]);
            const converter = 25.4 / 144;
            const totalPageHeightPx = paperHeight[values.format] / converter;
            const ganttHeightInPDF =
                HEADER_FOOTER_HEIGHT +
                tasksInLastPage * window.ganttInstance.config.row_height +
                window.ganttInstance.config.scale_height;
            const availableHeightInLastPage = totalPageHeightPx - ganttHeightInPDF - HEADER_FOOTER_HEIGHT;
            const histogramHeight = computeHistogramPDFDimension(values.format).height;
            canFit = availableHeightInLastPage - histogramHeight > 0;
            if (!canFit) {
                totalPages += 1;
            }

            console.log('eto ve', canFit);
        }
        window.ganttInstance.attachEvent('onBeforeTaskDisplay', (id, task) => !task.hidden);
        const pdfPromises = [];
        /* eslint-disable-next-line */
        while (minIndex <= ganttData.length - 1) {
            setProgressLabel(`Page ${currentPage} / ${totalPages}`);
            const iterationStart = Date.now();
            console.log('========= Start iteration');

            setProgressCount((100 * currentPage) / totalPages);
            let tasksInGantt = window.ganttInstance.serialize().data;
            if (nbTaskToPrint < tasksInGantt.length) {
                console.log('Start map in', Date.now() - iterationStart);

                /* eslint-disable-next-line */
                tasksInGantt = tasksInGantt.map((i, index) => {
                    const newTaskObj = i;
                    newTaskObj.hidden = index < minIndex || index > maxIndex;
                    return newTaskObj;
                });
                console.log('End map in', Date.now() - iterationStart);
                window.ganttInstance.parse({
                    data: tasksInGantt,
                    links: window.ganttInstance.getLinks(),
                });
                console.log('End parse in', Date.now() - iterationStart);
                window.ganttInstance.render();
            }
            console.log('Start resize iteration in', Date.now() - iterationStart);

            // const ganttWrapper = document.getElementById('gantt-wrapper');
            // /* eslint-disable-next-line */
            // ganttWrapper.style.width = paperWidthPx + ' !important px';
            console.log('End resize iteration in', Date.now() - iterationStart);
            console.log('Start autosize iteration in', Date.now() - iterationStart);
            window.ganttInstance.config.autosize = 'xy';
            window.ganttInstance.config.autosize_min_width =
                paperWidth[values.format] / (25.4 / 144) - totalColumnWidth;
            
            // gantt timeline width = paperWidth - table column width
            // const paperWidthPx = paperWidth[values.format] / (25.4 / 144);
            // window.ganttInstance.config.autosize_min_width =
            //     paperWidth[values.format] / (25.4 / 144) - totalColumnWidth;
            window.ganttInstance.render();

            console.log('End autosize iteration in', Date.now() - iterationStart);

            let generatedPDF;
            if ((isShowingNeeds && totalPages > 1) || !isShowingNeeds) {
                /* eslint-disable-next-line */
                generatedPDF = await generatePDF(
                    values,
                    t('page_indicator', { current: currentPage, total: totalPages }),
                    headerAndFooter.header,
                    headerAndFooter.footer,
                    'pdf',

                    {}
                );
            }
            // only do histogram operations when resources are shown
            if (isShowingNeeds) {
                const histogramOptions = document.getElementById('gantt-histogram-options');
                const histogramChartOptions = document.getElementById('gantt-histogram-chart-options');
                // waiting for resizing
                /* eslint-disable-next-line */
                await delay(3500);
                // getting current chart svg state
                const histogramSVG = document
                    .querySelector('#gantt-histogram-container .recharts-surface')
                    .cloneNode(true);
                /* eslint-disable-next-line */
                const yAxisSVG = document.querySelector('#gantt-histogram-options .recharts-surface').cloneNode(true);

                if (canFit && currentPage === totalPages) {
                    console.log('last page rendering');
                    const legendsData = generateLegendsHTML(
                        ganttHistogramChartNeedList.filter((i) => i.selected),
                        histogramChartOptions.offsetWidth,
                        histogramOptions.offsetHeight
                    );
                    const printPageWithLegend = async (legend) => {
                        const extraData = {
                            svg: histogramSVG.outerHTML,
                            legendsData: legend,
                            svgConfig: {
                                legendWidth: histogramOptions.offsetWidth + 0.5,
                                yAxis: yAxisSVG.outerHTML,
                            },
                        };
                        /* eslint-disable-next-line */
                        return await generatePDF(
                            values,
                            t('page_indicator', { current: totalPages, total: totalPages }),
                            headerAndFooter.header,
                            headerAndFooter.footer,
                            'pdf-histogram',
                            extraData
                        );
                    };
                    /* eslint-disable-next-line */
                    generatedPDF = await printPageWithLegend(legendsData.result);
                }
                if (!canFit && currentPage === totalPages - 1) {
                    const legendsData = generateLegendsHTML(
                        ganttHistogramChartNeedList.filter((i) => i.selected),
                        histogramChartOptions.offsetWidth,
                        histogramOptions.offsetHeight
                    );
                    setProgressLabel(`Page ${totalPages} / ${totalPages}`);

                    setProgressCount((100 * totalPages) / totalPages);
                    // add last gantt page before generating histogram pdf
                    // if (generatedPDF) {
                    pdfPromises.push(generatedPDF);
                    // } else {
                    //     break;
                    // }
                    const printHistogramWithLegend = async (legend) =>
                        /* eslint-disable-next-line */
                        generateHistogramPDF(
                            values,
                            t('page_indicator', { current: totalPages, total: totalPages }),
                            headerAndFooter.header,
                            headerAndFooter.footer,
                            'pdf',
                            {
                                svg: histogramSVG.outerHTML,
                                legendsData: legend,
                                svgConfig: {
                                    legendWidth: histogramOptions.offsetWidth + 0.5,
                                    yAxis: yAxisSVG.outerHTML,
                                },
                            }
                        );
                    /* eslint-disable-next-line */
                    generatedPDF = await printHistogramWithLegend(legendsData.result);
                }
            }
            // if (generatedPDF) {
            pdfPromises.push(generatedPDF);
            // } else {
            // break;
            // }
            minIndex = maxIndex + 1;
            maxIndex += nbTaskToPrint + 1;
            currentPage += 1;
            console.log('End iteration in', Date.now() - iterationStart);
        }
        console.log('Sending request finished in:', Date.now() - startProcess);
        setProgressLabel(t('compiling'));
        // must have something to merge
        Promise.all(pdfPromises).then((pdfBuffers) => {
            if (pdfBuffers.length > 0) {
                setProgressLabel(t('compiling'));
                mergePDFBuffers(pdfBuffers)
                    .then((mergedPdf) => {
                        downloadPDF(mergedPdf, planningSelected.name);
                        setProgressCount(100);
                        console.log('Process finished in:', Date.now() - startProcess);
                        window.ganttInstance.clearAll();
                        // setTimeout(() => {
                            window.saveScrollState = true;
                            dispatch(refreshPlanningSelected(true));
                        // }, 1000);
                        setPrintLoading(false);
                    })
                    .catch((error) => {
                        requestError('', t('print_planning'));
                        console.log('🚀 ~ file: PrintMenu.jsx:946 ~ Promise.all ~ error:', error);
                    });
            }
        });
    };

    return (
        <>
            <Tooltip title={t('print_planning')}>
                <QuickButton
                    type="button"
                    className="inline-block bg-primary text-center mr-3 align-top"
                    onClick={showModal}
                >
                    <PrintSvg className="mx-auto my-auto" />
                </QuickButton>
            </Tooltip>

            <Modal
                title={t('print_planning')}
                className="print-modal"
                centered
                open={isModalVisible}
                onCancel={onModalClose}
                footer={null}
                width={390}
                maskClosable={false}
                destroyOnClose
                maskStyle={{ backdropFilter: 'blur(4px)' }}
            >
                {printTemplates.length ? (
                    <Row gutter={[12, 0]} className="w-full">
                        <Col className="w-full">
                            <Form form={form} layout="vertical" onFinish={submitForm}>
                                <div className="flex w-full justify-between">
                                    <Form.Item
                                        style={{ width: 160 }}
                                        className="mb-0"
                                        name="printTemplate"
                                        label={t('print_template')}
                                        rules={[{ required: true, message: generalTranslation('required_field') }]}
                                        initialValue={printTemplates[0].id}
                                    >
                                        <Select
                                            options={printTemplates.reduce((agg, printTemplate) => {
                                                agg.push({ label: printTemplate.name, value: printTemplate.id });

                                                return agg;
                                            }, [])}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        style={{ width: 160 }}
                                        className="mb-0"
                                        name="format"
                                        label={t('document_format')}
                                        rules={[{ required: true, message: generalTranslation('required_field') }]}
                                        initialValue="A3"
                                    >
                                        <Select
                                            options={paperSizes.reduce((agg, documentType) => {
                                                agg.push({ label: documentType, value: documentType });

                                                return agg;
                                            }, [])}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="m-2">
                                    <Text type="secondary">{t('print_tip')}</Text>
                                </div>
                                <div className="mb-2">
                                    <p>{progressLabel}</p>
                                    <Progress
                                        strokeColor="#FBBE3F"
                                        percent={progressCount}
                                        size="small"
                                        showInfo={false}
                                    />
                                </div>
                                {printLoading}
                                <Form.Item className="mb-0">
                                    <div className="flex justify-between">
                                        <Button htmlType="button" className="mr-2" onClick={onModalClose}>
                                            {generalTranslation('cancel')}
                                        </Button>
                                        <Button type="primary" htmlType="submit" loading={printLoading}>
                                            {t('print')}
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                ) : (
                    <Empty description={t('no_template')} />
                )}
            </Modal>
        </>
    );
};

export default PrintMenu;
