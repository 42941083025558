import React, { useContext, useEffect, useState } from 'react';
import { Menu, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { CalendarMenuContext } from '../../contexts/app/CalendarMenuContext';
import DaysTab from '../calendars/DaysTab';
import PeriodsTab from '../calendars/PeriodsTab';
import CalendarsTab from '../calendars/CalendarsTab';
import { DaysTabContextProvider } from '../../contexts/calendar/DaysTabContext';
import { PeriodsTabContextProvider } from '../../contexts/calendar/PeriodsTabContext';
import { CalendarsTabContextProvider } from '../../contexts/calendar/CalendarsTabContext';

const CalendarMenu = () => {
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [modalBody, setModalBody] = useState(null);
    const { modalContent, onModalClose, isModalVisible, showModal } = useContext(CalendarMenuContext);

    useEffect(() => {
        switch (modalContent.key) {
            case 'days-tab':
                setModalBody(
                    <DaysTabContextProvider>
                        <DaysTab />
                    </DaysTabContextProvider>
                );
                break;
            case 'periods-tab':
                setModalBody(
                    <PeriodsTabContextProvider>
                        <PeriodsTab />
                    </PeriodsTabContextProvider>
                );
                break;
            case 'calendars-tab':
                setModalBody(
                    <CalendarsTabContextProvider>
                        <CalendarsTab />
                    </CalendarsTabContextProvider>
                );
                break;
            default:
                setModalBody(modalContent.body);
        }
    }, [modalContent]);

    return (
        <>
            <Menu.Item key="calendar_menu" onClick={showModal}>
                <FontAwesomeIcon icon={faCalendar} fixedWidth />
                {generalTranslation('calendars')}
            </Menu.Item>
            <Modal
                title={modalContent.title}
                className="calendar-modal"
                centered
                open={isModalVisible}
                onCancel={onModalClose}
                footer={null}
                width={1100}
                height={modalContent.key.indexOf('-tab') === -1 ? 'auto' : 820}
                maskClosable={false}
                destroyOnClose
            >
                {modalBody}
            </Modal>
        </>
    );
};
export default CalendarMenu;
