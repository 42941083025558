import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Modal, Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import TeamManagementTab from './TeamManagementTab';
import UserManagementMenu from '../UserManagementMenu';

const { TabPane } = Tabs;

const UserTeamManagementMenu = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { t } = useTranslation();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Menu.Item key="user_management_menu" onClick={showModal}>
                <FontAwesomeIcon icon={faUserFriends} fixedWidth />
                {t('menu.user_management')}
            </Menu.Item>
            <Modal
                title={t('menu.user_management')}
                centered
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                width={926}
                bodyStyle={{paddingTop: '0px', overflowY: 'auto'}}
            >
                <Tabs defaultActiveKey="usermanagement_tab" destroyInactiveTabPane>
                    <TabPane tab={t('menu.users')} key="usermanagement_tab">
                        <UserManagementMenu isProgram/>
                    </TabPane>
                    <TabPane tab={t('menu.teams')} key="teammanagement_tab">
                        <TeamManagementTab />
                    </TabPane>
                </Tabs>
            </Modal>
        </>
    );
};

export default UserTeamManagementMenu;
