import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import AuthService from '../../services/auth.service';

const LogoutMenu = () => {
    const history = useHistory();
    const { t } = useTranslation();

    const logout = () => {
        AuthService.logout();
        history.push('/login');
        window.location.reload();
    };
   
    return (
        <>
            <Menu.Item key="logout_menu" onClick={logout}>
                <FontAwesomeIcon icon={faSignOutAlt} fixedWidth />
                {t('menu.logout')}
            </Menu.Item>
        </>
    );
};

export default LogoutMenu;
