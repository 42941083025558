import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { BarChartOutlined} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const QuickButton = styled.button`
    width: 32px;
    height: 32px;
`;

const ExportHistogramData = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'export' });

    return (
        <>
            <Tooltip title={t('export_histogram')}>
                <QuickButton
                    type="button"
                    className={`inline-block text-center mr-3 bg-primary
                        `}
                    onClick={() => document.getElementById('export-histogram-excel-button')?.click()}
                >
                    <BarChartOutlined className="mx-auto" />
                </QuickButton>
            </Tooltip>
        </>
    );
};

export default ExportHistogramData;
