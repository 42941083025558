import React from 'react';
import { useSelector } from 'react-redux';
import { uniqBy } from 'lodash';
import { utils, writeFile } from 'xlsx';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { notificationError } from '../../helpers/notification';
import { ALL_JOBS, JOB_FIELD } from '../../constants/Jobs';
import { dateToJsDate } from '../../helpers/planning';
import DefaultUnifiedFieldId from '../../constants/DefaultPlanningField';
import { LINK_TYPES } from '../../constants/Links';
import { selectGanttData } from '../../redux/slices/app/planning.slice';
import getDurationUnitName, { getQuantityUnitName } from '../plannings/gantt_utils';

const QuickButton = styled.button`
    width: 32px;
    height: 32px;
`;

const ExportMenu = () => {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'export' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const columnsTranslation = useTranslation('translation', { keyPrefix: 'columns' }).t;
    const linksTranslation = useTranslation('translation', { keyPrefix: 'link_tab' }).t;
    const activityTabTranslation = useTranslation('translation', { keyPrefix: 'activity_tab' }).t;
    const { planningSelected, calendarsDictionary, activitiesDictionary } = useSelector(selectGanttData);

    

    // const getYieldUnit = (quantityId, durationId) =>
    //     `${getQuantityUnitName(quantityId)}/${getDurationUnitName(durationId)}`;

    const formatExportGanttData = (data) =>
        data.map((task) => {
            const taskDataToExport = {};
            taskDataToExport[columnsTranslation('id')] = task.identity;
            taskDataToExport[columnsTranslation('parent')] =
                task.serverId !== planningSelected.rootActivityId ? activitiesDictionary[task.parentId].identity : '';
            taskDataToExport[columnsTranslation('name')] = task.text;
            taskDataToExport[columnsTranslation('description')] = task.description;
            taskDataToExport[columnsTranslation('jobs')] = (task.jobId || [])
                .map((job) => {
                    if (job === ALL_JOBS) {
                        return generalTranslation('all_jobs', { lng: 'en' });
                    }
                    return job;
                })
                .join(';');
            taskDataToExport[columnsTranslation('calendar')] = calendarsDictionary?.[task.calendarId]?.name;
            taskDataToExport[columnsTranslation('start_date')] = dateToJsDate(task.startDate, null, false);
            taskDataToExport[columnsTranslation('end_date')] = dateToJsDate(task.endDate, null, false);
            taskDataToExport[columnsTranslation('duration')] = task.durationApi
                ? Number(task.durationApi).toFixed(2)
                : Number(0);
            taskDataToExport[columnsTranslation('duration_unit')] = getDurationUnitName(task.dayDefinitionId);
            taskDataToExport[columnsTranslation('progress')] = Number(task.progress * 100);
            const calculUnifiedField = {
                [DefaultUnifiedFieldId.duration]: 'Duration',
                [DefaultUnifiedFieldId.quantity]: 'Quantity',
                [DefaultUnifiedFieldId.yield]: 'Workrate',
            };
            taskDataToExport[columnsTranslation('calcul')] = calculUnifiedField[Number(task.champPMAutoId)];
            taskDataToExport[activityTabTranslation('round_duration')] =
                activitiesDictionary[task.serverId].roundedDuration;
            taskDataToExport[columnsTranslation('quantity')] = Number(task.quantity).toFixed(2);
            taskDataToExport[columnsTranslation('quantity_unit')] = getQuantityUnitName(task.quantityUnit);

            taskDataToExport[columnsTranslation('yield')] = Number(task.yield).toFixed(2);
            taskDataToExport[columnsTranslation('state')] = task.status;

            // user fields

            task.customFields?.forEach((customField) => {
                if (customField.name !== JOB_FIELD) {
                    // eslint-disable-next-line
                    taskDataToExport[customField.name] = customField.value[0];
                }
            });

            return taskDataToExport;
        });

    const LinkTypes = LINK_TYPES(i18n, 'en');

    const formatLinkData = (data) =>
        data.map((link) => {
            const linkDataToExport = {};
            linkDataToExport[t('predecessor_id')] = activitiesDictionary[link.activityPredecessorId].identity;
            linkDataToExport[t('successor_id')] = activitiesDictionary[link.activitySuccessorId].identity;
            linkDataToExport[generalTranslation('type')] = LinkTypes[link.type].label;
            linkDataToExport[linksTranslation('gap')] = link.decalage;
            linkDataToExport[linksTranslation('gap_unit')] = getDurationUnitName(link.dayDefinitionId);
            // linkDataToExport[linksTranslation('gap_unit')] = timeUnits.find(
            //     (item) => item.id === link.dayDefinitionId
            // )?.name;
            return linkDataToExport;
        });

    const exportData = () => {
        try {
            const tasksToExport = uniqBy(
                window.ganttInstance.serialize().data.filter((task) => {
                    if (task.isExtra) return false;
                    if (task.isResource) return false;
                    if (task.virtual) return false;
                    return true;
                }),
                'identity'
            );
            const linksToExport = uniqBy(window.ganttInstance.serialize().links, 'serverId').map(
                (link) => link.dataAPI
            );
            const tasks = formatExportGanttData(tasksToExport);
            const links = formatLinkData(linksToExport);
            console.log('🚀 ~ file: ExportMenu.jsx:103 ~ exportData ~ links:', links);

            console.log('🚀 ~ file: ExportMenu.jsx:82 ~ exportData ~ data:', tasks);
            // create a new workbook
            const wb = utils.book_new();
            // convert the data to a worksheet
            const taskSheetData = utils.json_to_sheet(tasks);
            // add the worksheet to the workbook
            utils.book_append_sheet(wb, taskSheetData, 'Tasks');
            // convert the data to a worksheet
            const linkSheetData = utils.json_to_sheet(links);
            // add the worksheet to the workbook
            utils.book_append_sheet(wb, linkSheetData, 'Links');
            // save the workbook as a file
            writeFile(wb, `${planningSelected.name}.xlsx`);
        } catch (error) {
            console.log('🚀 ~ file: ExportMenu.jsx:93 ~ exportData ~ error:', error);
            notificationError(t('export_xlsx'), generalTranslation('generic_error'));
        }
    };

    return (
        <>
            <Tooltip title={t('export_xlsx')}>
                <QuickButton
                    type="button"
                    className={`inline-block text-center mr-3 bg-primary
                        `}
                    onClick={() => exportData()}
                >
                    <DownloadOutlined className="mx-auto" />
                </QuickButton>
            </Tooltip>
        </>
    );
};

export default ExportMenu;
