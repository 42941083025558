import React, { useContext, useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { Button, Row, Input, Popconfirm, Space, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import TreeNodeUtils from 'tree-node-utils';
import { NeedsContext } from '../../contexts/needs/NeedsContext';
import DeleteButton from '../common/tables/DeleteButton';
import EditButton from '../common/tables/EditButton';
import { notificationError } from '../../helpers/notification';
import { PlanningNeedsContext } from '../../contexts/needs/PlanningNeedsContext';
import { generateString } from '../../helpers/string-helper';
import EditNeedModal from './EditNeedModal';
import { NEED_TYPE } from '../../constants/Needs';
import needService from '../../services/need.service';

const treeUtils = new TreeNodeUtils();
const filteringTreeFunction = (searchVal) => (node) => node.name.toLowerCase().trim().includes(searchVal.toLowerCase().trim());

const NeedsLibrary = ({ isPlanning, isReadOnly }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'needs' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [isLevel, setIsLevel] = useState(false);
    const [allNeeds, setAllNeeds] = useState([]);
    const [expandedNeeds, setExpandedNeeds] = useState([]);
    const { setSelectedNeed, NeedList, refreshNeedList } = useContext(isPlanning ? PlanningNeedsContext : NeedsContext);

    const EditNeedModalRef = useRef();

    const getLevelKeys = (list) =>
        list.reduce((old, current) => {
            let newValue = [...old];
            if (current.isLevel) {
                newValue.push(current.key);
                if (current.children?.length) {
                    newValue = [...newValue, ...getLevelKeys(current.children)];
                }
            }
            return newValue;
        }, []);

    useEffect(() => {
        if (NeedList) {
            setAllNeeds(NeedList);
            setExpandedNeeds(getLevelKeys(NeedList));
        }
    }, [NeedList]);

    const addNewNeed = () => {
        flushSync(() => {
            setIsLevel(false);
        });
        setSelectedNeed(null);
        EditNeedModalRef.current.openModal();
    };

    const addNewLevel = () => {
        flushSync(() => {
            setIsLevel(true);
        });
        setSelectedNeed(null);
        EditNeedModalRef.current.openModal();
    };

    const editNeed = (need, isLevelNeed) => {
        setSelectedNeed(need);
        flushSync(() => {
            setIsLevel(isLevelNeed);
        });
        EditNeedModalRef.current.openModal();
    };

    const removeNeed = async (need) => {
        try {
            await needService.deleteNeed(need.id);
            refreshNeedList('delete', need);
        } catch (error) {
            notificationError(t('needs_library'), generalTranslation('generic_error'));
        }
    };

    const columns = [
        {
            title: generalTranslation('name'),
            dataIndex: 'name',
            key: 'name',
            sortDirections: ['ascend', 'descend'],
            sorter: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
            render: (text, record) =>
                record.isLevel ? (
                    <label>{record.name}</label>
                ) : (
                    <span className={`${record.needType === NEED_TYPE.CONSUMMABLE ? 'italic' : ''}`}>
                        {record.name}
                    </span>
                ),
            width: '50%',
        },
        {
            title: generalTranslation('description'),
            dataIndex: 'description',
            key: 'desc',
            sortDirections: ['ascend', 'descend'],
            sorter: (a, b) => (a.description ?? '').localeCompare(b.description ?? ''),
            width: '20%',
        },
        {
            title: generalTranslation('color'),
            dataIndex: 'color',
            key: 'color',
            align: 'center',
            render: (text, record) => (
                <div className="flex justify-center w-full">
                    <div style={{ background: record.color, width: '20px', height: '20px' }} />
                </div>
            ),
            width: '50px',
        },
        {
            title: '',
            key: 'action',
            align: 'right',
            render: (text, record) => !isReadOnly && (
                <Space size="middle">
                    <EditButton onClick={() => editNeed(record, record.isLevel)} className="mr-2" />
                    <Popconfirm
                        title={generalTranslation('delete_confirm')}
                        onCancel={() => {}}
                        onConfirm={() => removeNeed(record)}
                        okText={generalTranslation('yes')}
                        cancelText={generalTranslation('no')}
                    >
                        {!record.isDefault && <DeleteButton />}
                    </Popconfirm>
                </Space>
            ),
            width: '50px',
        },
    ];
    const handleSearch = (e) => {
        if (e.target.value && e.target.value !== '') {
            const filteredResult = treeUtils.filterNodes(NeedList, filteringTreeFunction(e.target.value));
            setAllNeeds(filteredResult);
        } else {
            setAllNeeds(NeedList);
        }
    };
    return (
        <div>
                <Row className="flex w-full justify-between my-2">
                    <Input.Search
                        placeholder={generalTranslation('search')}
                        onChange={handleSearch}
                        allowClear
                        style={{ width: 400 }}
                        className="mr-12"
                    />
                    <div>
                        <Button type="primary" disabled={isReadOnly} className="mr-2" onClick={() => addNewLevel()}>
                            {t('add_level')}
                        </Button>
                        <Button type="primary" disabled={isReadOnly}  onClick={() => addNewNeed()}>
                            {t('add_need')}
                        </Button>
                    </div>
                </Row>
                <Table
                    columns={columns}
                    dataSource={allNeeds}
                    className='needs-table'
                    size="small"
                    pagination={{ pageSize: 10 }}
                    expandable={{
                        // defaultExpandAllRows: true,
                        expandedRowKeys: expandedNeeds,
                        onExpandedRowsChange: (rows) => setExpandedNeeds(rows)
                    }}
                    scroll={{ scrollToFirstRowOnChange: true, y: '68vh' }}
                />
                <EditNeedModal
                    key={generateString(7)}
                    isLevel={isLevel}
                    isPlanning={isPlanning}
                    ref={EditNeedModalRef}
                />
        </div>
    );
};

export default NeedsLibrary;
