import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Menu, Tooltip } from 'antd';
import { DatabaseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import NeedsLibrary from './NeedsLibrary';

const QuickButton = styled.button`
    width: 32px;
    height: 32px;
`;

const NeedsMenu = ({ isPlanning, isReadOnly }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'needs' });
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            {isPlanning ? (
                <Tooltip title={t('needs_library')}>
                    <QuickButton
                        type="button"
                        className={`flex items-center ${
                            isModalVisible ? 'bg-black text-white' : 'bg-primary'
                        }`}
                        title={t('needs_library')}
                        onClick={() => showModal()}
                    >
                        <DatabaseOutlined className="mx-auto" />
                    </QuickButton>
                </Tooltip>
            ) : (
                <Menu.Item key="resource_menu" onClick={() => showModal()}>
                    <DatabaseOutlined style={{ marginRight: '5px' }} />
                    {t('needs_library')}
                </Menu.Item>
            )}

            <Modal
                title= {`${isPlanning ? t('planning_needs_library') : t('global_needs_library')}`}
                className="custom-fields-modal"
                centered
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                width={926}
                height="auto"
                bodyStyle={{ paddingTop: '0px', minHeight: '50vh' }}
            >
                <NeedsLibrary isPlanning={isPlanning} isReadOnly={isReadOnly} />
            </Modal>
        </>
    );
};

export default NeedsMenu;
