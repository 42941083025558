import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { PrintTemplatesMenuContext } from '../../contexts/app/PrintTemplatesMenuContext';
import PrintTemplatesMain from '../print-templates/PrintTemplatesMain';

const PrintTemplateMenu = () => {
    const { modalContent, onModalClose, isModalVisible, showModal } = useContext(PrintTemplatesMenuContext);
    const { t } = useTranslation();

    return (
        <>
            <Menu.Item key="print_template_menu" onClick={showModal}>
                <FontAwesomeIcon icon={faPrint} fixedWidth />
                {t('print.print_template_title')}
            </Menu.Item>
            <Modal
                title={t('print.print_template_title')}
                className="print-template-modal"
                centered
                open={isModalVisible}
                onCancel={onModalClose}
                footer={null}
                width={670}
                height={modalContent ? 'auto' : 530}
                maskClosable={false}
                destroyOnClose
            >
                {modalContent || <PrintTemplatesMain />}
            </Modal>
        </>
    );
};

export default PrintTemplateMenu;
