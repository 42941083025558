import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';

import UserEditionForm from '../users/UserEditionForm';
import UserDeleteForm from '../users/UserDeleteForm';
import UserList from '../users/UserList';

const UserManagementMenu = ({ isProgram }) => {
    const viewsEnum = Object.freeze({ list: 'list', edit: 'edit', remove: 'remove' });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [view, setView] = useState(viewsEnum.list);
    const [user, setUser] = useState(null);
    const { t } = useTranslation();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const showList = () => {
        setView(viewsEnum.list);
    };

    const addUser = () => {
        setUser({});
        setView(viewsEnum.edit);
    };

    const editUser = (record) => {
        setUser(record);
        setView(viewsEnum.edit);
    };

    const removeUser = (record) => {
        setUser(record);
        setView(viewsEnum.remove);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setView(viewsEnum.list);
    };

    let modalContents;
    switch (view) {
        case viewsEnum.edit:
            modalContents = <UserEditionForm user={user} showList={showList} />;
            break;
        case viewsEnum.remove:
            modalContents = <UserDeleteForm user={user} showList={showList} />;
            break;
        default:
            modalContents = <UserList addUser={addUser} editUser={editUser} removeUser={removeUser} />;
    }

    return (
        <>
            {isProgram ? (
                <>{modalContents}</>
            ) : (
                <>
                    <Menu.Item key="user_management_menu" onClick={showModal}>
                        <FontAwesomeIcon icon={faUserFriends} fixedWidth />
                        {t('menu.user_management')}
                    </Menu.Item>
                    <Modal
                        title={t('menu.user_management')}
                        centered
                        open={isModalVisible}
                        onCancel={handleCancel}
                        footer={null}
                        width={926}
                    >
                        {modalContents}
                    </Modal>
                </>
            )}
        </>
    );
};

export default UserManagementMenu;
