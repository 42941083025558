import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Tooltip } from 'antd';
import styled from 'styled-components';
import { gantt } from 'dhtmlx-gantt';
import { notificationError, notificationInfo } from '../../helpers/notification';
import ImportExcelStepper from '../import/ImportExcelStepper';
import {readData} from '../../helpers/import-utils';

/* eslint-disable camelcase */
const QuickButton = styled.button`
    width: 32px;
    height: 32px;
`;
const ExcelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const ImportMenu = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'modal_import' });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [excelData, setExcelData] = useState({tasks: [], links: []});
    const hiddenFileInput = React.useRef(null);
    const [isLoadingFile, setIsLoadingFile] = useState(false);

    const showModalImport = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsLoadingFile(false)
        setIsModalVisible(false);
    };

    const importExcel = async (event) => {
        setIsLoadingFile(true);
        const fileUploaded = event.target.files[0];
        if (fileUploaded.type !== ExcelFileType) {
            notificationError(t('data_import'), t('wrong_file_type'));

            hiddenFileInput.current.value = '';
            return;
        }
        if (gantt.importFromExcel) {
            const onErrorColumn = () => notificationInfo(t('data_import'), t('column_validation'))
            readData(fileUploaded, onErrorColumn).then((data) => {
                console.log('🚀 ~ importExcel ~ linkData:', data);
                showModalImport();
                setExcelData(data);
            }).catch(() => {
                setIsLoadingFile(false)
            });
            
        } else {
            notificationError(t('data_import'), t('module_loading'));
        }

        hiddenFileInput.current.value = '';
    };
    return (
        <>
            <Tooltip title={t('data_import')}>
                <QuickButton
                    type="button"
                    className={`${
                        isLoadingFile ? ' animate-bounce' : ''
                    } inline-block bg-primary text-center align-top`}
                    title="Importer"
                    onClick={() => {
                        hiddenFileInput.current.click();
                    }}
                >
                    XLS
                </QuickButton>
            </Tooltip>

            <input
                ref={hiddenFileInput}
                onChange={importExcel}
                type="file"
                style={{ display: 'none' }}
                multiple={false}
                accept={ExcelFileType}
            />
            <Modal
                title={t('data_import')}
                className="project-modal"
                centered
                open={isModalVisible}
                onCancel={handleCancel}
                width={window.innerWidth * 0.9}
                height={window.innerHeight * 0.9}
                footer={null}
                bodyStyle={{paddingTop: 0, paddingBottom: 12}}
                maskClosable={false}
                destroyOnClose
                keyboard={false}
            >
                {excelData && <ImportExcelStepper importData={excelData} />}
            </Modal>
        </>
    );
};

export default ImportMenu;
