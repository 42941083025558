import React, {useState} from 'react';
import { Menu, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import ProfileModification from '../profile/ProfileModification';
import PasswordModification from '../profile/PasswordModification';

const ProfileMenu = () => {
    const viewsEnum = Object.freeze({ profileDetails: 'profileDetails', password: 'password' });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [view, setView] = useState(viewsEnum.list);
    const {t} = useTranslation();

    const showModal = () => {
        setIsModalVisible(true);
        setView(viewsEnum.profileDetails);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    let modalContents;
    if (view === viewsEnum.password) {
        modalContents = (
            <PasswordModification
                onCancel={handleCancel}
                showProfileDetailsForm={() => setView(viewsEnum.profileDetails)}
            />
        );
    } else {
        modalContents = (
            <ProfileModification onCancel={handleCancel} showPasswordForm={() => setView(viewsEnum.password)} />
        );
    }

    return (
        <>
            <Menu.Item key="profile_menu" onClick={showModal}>
                <FontAwesomeIcon icon={faUser} fixedWidth />
                {t('wallet.header.profile_menu_title')}
            </Menu.Item>
            <Modal title={t('wallet.header.profile_menu_title')} centered open={isModalVisible} onCancel={handleCancel} footer={null}>
                {modalContents}
            </Modal>
        </>
    );
};

export default ProfileMenu;
