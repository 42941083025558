import React, { useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUsers } from '@fortawesome/free-solid-svg-icons';
import TeamEditionForm from '../../teams/TeamEditionForm';
import TeamDeleteForm from '../../teams/TeamDeleteForm';
import TeamList from '../../teams/TeamList';


const TeamManagementTab = () => {
    const viewsEnum = Object.freeze({ list: 'list', edit: 'edit', remove: 'remove' });
    const [view, setView] = useState(viewsEnum.list);
    const [team, setTeam] = useState(null);


    const showList = () => {
        setView(viewsEnum.list);
    };

    const addTeam = () => {
        setTeam({});
        setView(viewsEnum.edit);
    };

    const editTeam = (record) => {
        setTeam(record);
        setView(viewsEnum.edit);
    };

    const removeTeam = (record) => {
        setTeam(record);
        setView(viewsEnum.remove);
    };


    let modalContents;
    switch (view) {
        case viewsEnum.edit:
            modalContents = <TeamEditionForm team={team} showList={showList} />;
            break;
        case viewsEnum.remove:
            modalContents = <TeamDeleteForm team={team} showList={showList} />;
            break;
        default:
            modalContents = <TeamList addTeam={addTeam} editTeam={editTeam} removeTeam={removeTeam} />;
    }

    return (
        <>
                {modalContents}
        </>
            
    );
};

export default TeamManagementTab;
