import React, { forwardRef, useContext, useImperativeHandle, useState, useEffect } from 'react';
// import { difference } from 'lodash';
import styled from 'styled-components';
import { Button, Form, Input, Modal, Select } from 'antd';
import { useSelector } from 'react-redux';
import { SketchPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { NeedsContext } from '../../contexts/needs/NeedsContext';
import { PlanningNeedsContext } from '../../contexts/needs/PlanningNeedsContext';
import { requestError } from '../../helpers/notification';
import { getArrayInListByCondition } from '../../helpers/tree-helper';
import { NEED_QUANTITY_MODE, NEED_TYPE, NEED_VISIBILITY } from '../../constants/Needs';
import needService from '../../services/need.service';
import quantityUnits from '../plannings/activity_config';
import { selectPlanningSelected } from '../../redux/slices/app/planning.slice';
import { selectTimeUnits } from '../../redux/slices/app/calendar.slice';

const ColorCover = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

const ColorPicker = styled.div`
    position: absolute;
    right: 1px;
    z-index: 2;
`;

const EditNeedModal = forwardRef(({ isLevel, isPlanning }, ref) => {
    const { t } = useTranslation('translation', { keyPrefix: 'needs' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [isVisible, setIsVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [needParentList, setNeedParentList] = useState([]);
    const [needColor, setNeedColor] = useState('#000000');
    const [needType, setNeedType] = useState(NEED_TYPE.NON_CONSUMMABLE);
    const [showNeedColorPicker, setShowNeedColorPicker] = useState(false);
    const { selectedNeed, NeedList, refreshNeedList, setSelectedNeed } = useContext(
        isPlanning ? PlanningNeedsContext : NeedsContext
    );
    const planningSelected = useSelector(selectPlanningSelected);
    const timeUnits = useSelector(selectTimeUnits) 
    const [form] = Form.useForm();
    const needQuantityMode = Form.useWatch('quantityMode', form);
    const needTypeOptions = [
        {
            value: NEED_TYPE.NON_CONSUMMABLE,
            label: t('non_consummable'),
        },
        {
            value: NEED_TYPE.CONSUMMABLE,
            label: t('consummable'),
        },
    ];
    const needQuantityModeOptions = [
        {
            value: NEED_QUANTITY_MODE.FIXE,
            label: t('quantity_mode_fix'),
        },
        {
            value: NEED_QUANTITY_MODE.QUANTITY,
            label: t('quantity_mode_quantity'),
        },
        {
            value: NEED_QUANTITY_MODE.TEMPORAL,
            label: t('quantity_mode_temporal'),
        },
    ];
    useImperativeHandle(ref, () => ({
        openModal() {
            setIsVisible(true);
        },
    }));

    const onCancel = () => {
        setIsVisible(false);
        setNeedColor(null);
        setSelectedNeed(null);
        form.resetFields();
    };

    useEffect(() => {
        if (selectedNeed) {
            setIsEdit(true);
            form.setFieldsValue({
                ...selectedNeed,
                skills: selectedNeed.skills?.map((i) => i.id),
            });
            setNeedColor(selectedNeed.color ?? '#000000');
            setNeedType(selectedNeed.needType);
        } else {
            setIsEdit(false);
            form.resetFields();
            form.setFieldsValue({
                parent: NeedList?.[0]?.id,
                description: null,
                quantity: 1,
                skills: [],
            });
            setNeedColor('#000000');
        }
        console.log('useEffect', isVisible);
    }, [selectedNeed]);

    useEffect(() => {
        if (NeedList) {
            setNeedParentList(getArrayInListByCondition(NeedList, 'isLevel', true));
        }
    }, [NeedList]);

    const onFinish = async ({ name, description, parent, quantityMode, timeUnitId, quantityUnit }) => {
        try {
            if (isEdit) {
                
                      let editedData;
                      if (isLevel) {
                        editedData = await needService.updateNeedLevel(selectedNeed.id, {
                            name,
                            description,
                            parentId: parent,
                        })
                      }
                      if (!isLevel && needType === NEED_TYPE.NON_CONSUMMABLE) {
                        editedData = await needService.updateNeed(selectedNeed.id, {
                            name,
                            description,
                            parentId: parent,
                            color: needColor,
                        });
                      } 
                      if (!isLevel && needType === NEED_TYPE.CONSUMMABLE) {
                        editedData = await needService.updateNeed(selectedNeed.id, {
                            name,
                            description,
                            parentId: parent,
                            color: needColor,
                            quantityMode,
                            timeUnitId,
                            quantityUnit
                        });
                      } 
                refreshNeedList('edit', {
                    ...selectedNeed,
                    ...editedData,
                    open: isLevel,
                    parent,
                    key: editedData.id.toString(),
                    color: isLevel ? null : needColor ?? '#000000',
                });
            } else {
                      let newData;
                      if (isLevel) {
                        newData = await needService.createNeedLevel(
                            name,
                            description,
                            parent,
                            isPlanning ? NEED_VISIBILITY.PLANNING : NEED_VISIBILITY.GLOBAL,
                            isPlanning ? planningSelected.rootActivityId : null
                        )
                      }
                      if (!isLevel && needType === NEED_TYPE.NON_CONSUMMABLE) {
                        newData = await needService.createNeed(
                            name,
                            description,
                            parent,
                            needColor,
                            needType,
                            isPlanning ? NEED_VISIBILITY.PLANNING : NEED_VISIBILITY.GLOBAL,
                            isPlanning ? planningSelected.rootActivityId : null
                        );
                      } 
                      if (!isLevel && needType === NEED_TYPE.CONSUMMABLE) {
                        newData = await needService.createConsumableNeed(
                            name,
                            description,
                            parent,
                            needColor,
                            needType,
                            isPlanning ? NEED_VISIBILITY.PLANNING : NEED_VISIBILITY.GLOBAL,
                            isPlanning ? planningSelected.rootActivityId : null,
                            quantityMode,
                            timeUnitId
                        );
                      } 
                refreshNeedList('new', {
                    ...newData,
                    parent,
                    key: newData.id.toString(),
                    color: isLevel ? null : needColor ?? '#000000',
                    open: true,
                });
            }
            onCancel();
        } catch (error) {
            console.log('🚀 ~ file: EditNeedModal.jsx:169 ~ onFinish ~ error', error);
            requestError(error, t('needs_library'));
        }
    };

    return (
        <Modal
            title={`${isEdit ? generalTranslation('edit') : generalTranslation('add')} ${
                isLevel ? t('level') : t('need')
            }`}
            className="add-planning-modal"
            centered
            open={isVisible}
            onCancel={onCancel}
            footer={null}
        >
            <Form form={form} layout="vertical" onFinish={onFinish}>
                {!isLevel && (
                    <Form.Item label={generalTranslation('type')} required>
                        <Select
                            value={needType}
                            disabled={isEdit}
                            options={needTypeOptions}
                            onChange={(val) => setNeedType(val)}
                        />
                    </Form.Item>
                )}
                <Form.Item
                    name="name"
                    label={generalTranslation('name')}
                    rules={[{ required: true, message: generalTranslation('required_field') }]}
                >
                    <Input type="text" disabled={selectedNeed?.isDefault}  placeholder={generalTranslation('name')} />
                </Form.Item>
                <Form.Item name="description" label={generalTranslation('description')}>
                    <Input.TextArea rows={3} />
                </Form.Item>
                {!isLevel && (
                    <>
                        <Form.Item className="w-1/6" label={generalTranslation('color')}>
                            <Input
                                type="color"
                                value={needColor ?? '#000000'}
                                onClick={(event) => {
                                    setShowNeedColorPicker(true);
                                    event.preventDefault();
                                }}
                            />
                            {showNeedColorPicker && (
                                <ColorPicker>
                                    <ColorCover
                                        onClick={() => {
                                            setShowNeedColorPicker(false);
                                        }}
                                    />
                                    <SketchPicker
                                        color={needColor ?? '#000000'}
                                        onChangeComplete={(color) => {
                                            setNeedColor(color?.hex);
                                        }}
                                        disableAlpha
                                    />
                                </ColorPicker>
                            )}
                        </Form.Item>
                    </>
                )}
                {needType ===  NEED_TYPE.CONSUMMABLE && (
                    <>
                        <Form.Item
                            name="quantityMode"
                            label={t('quantity_mode')}
                            rules={[{ required: true, message: generalTranslation('required_field') }]}
                        >
                            <Select
                                defaultActiveFirstOption
                                options={needQuantityModeOptions}
                            />
                        </Form.Item>
                        <Form.Item
                            name="quantityUnit"
                            label={t('unit')}
                            rules={[{ required: true, message: generalTranslation('required_field') }]}
                        >
                            <Select options={quantityUnits} />
                        </Form.Item>
                        {needQuantityMode === NEED_QUANTITY_MODE.TEMPORAL && (
                            <Form.Item name="timeUnitId" label={t('time_unit')} rules={[{ required: true, message: generalTranslation('required_field') }]}>
                                <Select
                                    defaultActiveFirstOption
                                    options={timeUnits.reduce((agg, timeUnit) => {
                                        agg.push({ label: timeUnit.name, value: timeUnit.id });

                                        return agg;
                                    }, [])}
                                />
                            </Form.Item>
                        )}
                    </>
                )}
                {/* {needType === 'consummable' && (
                    <>
                        <Form.Item
                            name="cost"
                            label={t('cost')}
                            rules={[{ required: true, message: generalTranslation('required_field') }]}
                        >
                            <InputNumber className='w-full' type="text" />
                        </Form.Item>
                    </>
                )} */}
                {/* <Form.Item
                            name="costCenter"
                            label={t('cost_center')}
                            rules={[{ required: true, message: generalTranslation('required_field') }]}
                        >
                            <Select options={[]} />
                        </Form.Item> */}
                {!selectedNeed?.isDefault && (
                    <Form.Item
                        name="parent"
                        label="Parent"
                        rules={[{ required: true, message: generalTranslation('required_field') }]}
                    >
                        <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                            options={(needParentList).flatMap((parent) => {
                                if (selectedNeed && selectedNeed.id === parent.id) {
                                    return [];
                                }
                                return {
                                value: parent.id,
                                label: parent.name,
                            }})}
                        />
                    </Form.Item>
                )}

                <Form.Item className="mb-0 text-right">
                    <Button type="primary" htmlType="submit">
                        {`${isEdit ? generalTranslation('edit') : generalTranslation('add')}`}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
});

export default EditNeedModal;
