import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTable } from '@fortawesome/free-solid-svg-icons';


const ProgramMenu = () => {
    const history = useHistory();
    const { t } = useTranslation();

    const gotoPrograms = () => {
        history.push('/programs');
    };

    return (
        <>
            <Menu.Item key="logout_menu" onClick={gotoPrograms}>
                <FontAwesomeIcon icon={faTable} fixedWidth />
                {t('menu.programs')}
            </Menu.Item>
        </>
    );
};

export default ProgramMenu;
